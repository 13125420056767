import http from '../lib/http'
import judgStatus from './base'
const RESTURL = '/back/getOrder'
const BUILDINGURL = '/back/getAllBuilding'
const FLOORURL = '/back/getAllFloor'
const REFUND = '/back/orderRefund'
const API = {
    getOrder(data) {
        return new Promise((resolve, reject) => {
            http.post(RESTURL, data).then(res => {
                const scs = judgStatus(res)
                resolve(scs)
            }).catch(error => {
                reject(error)
            })
        })
    },
    getAllBuilding(building) {
        return new Promise((resolve, reject) => {
            http.post(BUILDINGURL, building).then(res => {
                const scs = judgStatus(res)
                resolve(scs)
            }).catch(error => {
                reject(error)
            })
        })
    },
    getAllFloor(floor) {
        return new Promise((resolve, reject) => {
            http.post(FLOORURL, floor).then(res => {
                const scs = judgStatus(res)
                resolve(scs)
            }).catch(error => {
                reject(error)
            })
        })
    },
    orderRefund(id) {
        return new Promise((resolve, reject) => {
            http.post(REFUND, id).then(res => {
                const scs = judgStatus(res)
                resolve(scs)
            }).catch(error => {
                reject(error)
            })
        })
    }
}
export default API
