<template>
  <!-- <div :style="{ 'margin-top': '-40px', transform: 'translateY('+top+'px)'}">
    <div
      class="loading-box"
      v-if="!isRefresh"
      :style="{
        color: '#969799',
        'font-size': '14px',
        margin: '10px 0',
      }"
    >
      <van-icon
        name="down"
        :style="{
          'margin-right': '10px',
          transform: flag ? 'rotate(180deg)' : '',
        }"
      />
      <span>{{ loadingText }}</span>
    </div>
    <van-loading v-else style="padding: 15px 0">{{ loadingText }}</van-loading>
    <div
      class="dist-order-box"
      @touchstart="touchStart($event)"
      @touchmove="touchMove($event)"
      @touchend="touchEnd($event, resetQueryParams, getOrderList)"
    > -->
  <div class="dist-order-box">
    <div class="dist-order-header">
      <div class="right flex-1 d-flex align-center">
        <span class="select-data" @click="showBottom = true">{{
          dateValue
        }}</span>
        <van-dropdown-menu active-color="#1989fa" class="flex-1">
          <van-dropdown-item
            v-model="value2"
            :options="option2"
            @change="changeBrandItem"
          />
          <van-dropdown-item
            v-model="value3"
            :options="option3"
            @change="changeStatusItem"
          />
        </van-dropdown-menu>
        <div class="all-status">
          <van-button type="success" size="small" @click="isAllDelivery"
            >整层已送</van-button
          >
        </div>
      </div>
    </div>
    <div class="building-layer-box">
      <div class="item d-flex align-center justify-between">
        <div
          class="d-flex justify-center align-center flex-column"
          style="min-width: 60px"
        >
          <span>校区</span>
          <van-popover
            v-model:show="showCampusPopover"
            placement="bottom-start"
            v-if="permissions == 'all'"
          >
            <van-grid
              square
              clickable
              :border="false"
              column-num="3"
              style="width: 240px; padding: 10px; box-sizing: border-box"
            >
              <van-grid-item
                v-for="item in campusList"
                :key="item"
                @click="selectCampusFun(item)"
              >
                {{ item.campus_name }}
              </van-grid-item>
            </van-grid>
            <template #reference>
              <van-button type="default" size="mini">{{
                selectCampus == "请选择" ? selectCampus : selectCampus
              }}</van-button>
            </template>
          </van-popover>
        </div>
        <div class="right-box">
          <div>{{ campus_name_user }}</div>
        </div>
      </div>
      <div class="item d-flex align-center justify-between">
        <div
          class="d-flex justify-center align-center flex-column"
          style="min-width: 60px"
        >
          <span>幢</span>
          <van-popover
            v-model:show="showBuildingPopover"
            placement="bottom-start"
            @open="openBuildingList"
          >
            <van-grid
              square
              clickable
              :border="false"
              column-num="3"
              style="width: 240px; padding: 10px; box-sizing: border-box"
            >
              <van-grid-item
                v-for="item in buildArr"
                :key="item"
                @click="showBuilding(item)"
              >
                {{ item.name }}
              </van-grid-item>
            </van-grid>
            <template #reference>
              <van-button type="default" size="mini">{{
                selectBuild == "请选择" ? selectBuild : selectBuild
              }}</van-button>
            </template>
          </van-popover>
        </div>
        <div class="scroll-right">
          <div :style="{width:dongShopNum*150+'px'}">
            <div class="scroll-right-item" v-for="item in dongShopData" :key="item">
              <p>{{ item.name }}</p>
              <p>{{ item.value }}桶</p>
            </div>
          </div>
        </div>
      </div>
      <div class="item d-flex align-center justify-between">
        <div
          class="d-flex justify-center align-center flex-column"
          style="min-width: 60px"
        >
          <span>层</span>
          <van-popover
            v-model:show="showLayerPopover"
            placement="bottom-start"
            @open="openFloorList"
          >
            <van-grid
              square
              clickable
              :border="false"
              column-num="3"
              style="width: 240px; padding: 10px; box-sizing: border-box"
            >
              <van-grid-item
                v-for="item in floorArr"
                :key="item"
                :text="item.name"
                @click="showLayer(item)"
              />
            </van-grid>
            <template #reference>
              <van-button type="default" size="mini"
                >{{
                  selectFloor == "请选择" ? selectFloor : selectFloor > 0 ? selectFloor + "层" :selectFloor
                }}
              </van-button>
            </template>
          </van-popover>
        </div>
        <div class="scroll-right">
          <div :style="{width:dongShopNum*150+'px'}">
            <div class="scroll-right-item" v-for="item in cengShopData" :key="item">
              <p>{{ item.name }}</p>
              <p>{{ item.value }}桶</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="table-box">
      <table class="fl-table" border="2">
        <thead>
          <tr>
            <!-- <th>学校</th> -->
            <th style="width: 45px">房号</th>
            <th>品牌</th>
            <th style="width: 40px">数量</th>
            <th style="width: 75px;">状态</th>
            <th style="width: 50px;">时间</th>
            <th style="width: 75px;">配送员</th>
          </tr>
        </thead>
        <van-skeleton v-if="skeleton" title :row="10" />
        <tbody v-else>
          <van-empty
            v-if="list.list.length === 0"
            class="custom-image"
            :image="emptyImg"
            description="没有数据"
          />
          <tr v-for="item in list.list" :key="item.id">
            <!-- <td>{{ item.campus_name }}</td> -->
            <td style="width: 45px">{{ item.room_name[0].name }}</td>
            <td>{{ item.brand_name }}</td>
            <td style="width: 40px">{{ item.num }}</td>
            <td style="width: 75px;">
              <van-button
                :disabled="
                  item.status == 2
                    ? false
                    : item.status == 3
                    ? true
                    : item.status == 4
                    ? true
                    : false
                "
                :type="
                  item.status == 2
                    ? 'danger'
                    : item.status == 3
                    ? 'success'
                    : 'danger'
                "
                size="mini"
                @click="isOneDelivery(item)"
                >{{
                  item.status == 2
                    ? "未送"
                    : item.status == 3
                    ? "已送"
                    : item.status == 4
                    ? "退款"
                    : "未送"
                }}</van-button
              >
            </td>
            <td style="width: 50px;">
              {{
                item.status == 2
                  ? item.pay_at
                  : item.status == 3
                  ? item.delivery_at
                  : item.status == 4
                  ? item.refund_at
                  : item.created_at
              }}
            </td>
            <td v-if="item.status !== 2" style="width: 75px;">
              {{ item.user_item ? item.user_item.username : "暂无" }}
            </td>
            <td v-else style="width: 75px;">
              <van-button size="mini" type="warning" @click="cancelOrder(item)"
                >退款</van-button
              >
            </td>
          </tr>
        </tbody>
      </table>
      <div
        class="pagination-box d-flex align-center"
        style="margin-top: 10px;margin-bottom: 10px; justify-content: space-around"
      >
        <div class="pagination">
          <van-pagination
            v-model="currentPage"
            :page-count="list.pageCount"
            :show-page-size="3"
            force-ellipses
            @change="changePage"
          />
        </div>
      </div>
    </div>
    <van-popup
      v-model:show="showBottom"
      position="bottom"
      :style="{ height: '47%' }"
    >
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="选择日期"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="confirmDate"
        @cancel="showBottom = false"
      />
    </van-popup>
    <van-dialog
      v-model:show="refundShow"
      title="退款提示"
      show-cancel-button
      @confirm="confirmRefund"
    >
      <p style="padding: 0 15px">{{ refundText }}</p>
      <van-field
        v-model="refundParams.reason"
        rows="1"
        autosize
        label="退款理由"
        type="textarea"
        placeholder="请输入理由（选填）"
        style="margin-bottom: 16px"
      />
    </van-dialog>
  </div>
  <!-- </div> -->
</template>

<script>
import { ref, reactive, computed } from "vue";
import api from "../../api/order";
import httpDeliver from "../../api/deliveryData";
import httpOrder from "../../api/order";
import refresh from "../../state/refresh-data";
import httpCampus from "../../api/getCampus";
import Cookies from "js-cookie";
import { setBuildText,setCengText,setRoomText } from '../../lib/setBuild'
import {
  Popup,
  Toast,
  Popover,
  Grid,
  GridItem,
  Button,
  Pagination,
  Skeleton,
  Empty,
  Dialog,
  DropdownMenu,
  DropdownItem,
  DatetimePicker,
  Field,
  // Loading,
  // Icon,
} from "vant";
export default {
  name: "DistOrder",
  components: {
    "van-popup": Popup,
    "van-popover": Popover,
    "van-grid": Grid,
    "van-button": Button,
    "van-grid-item": GridItem,
    "van-pagination": Pagination,
    "van-skeleton": Skeleton,
    "van-empty": Empty,
    "van-dropdown-menu": DropdownMenu,
    "van-dropdown-item": DropdownItem,
    "van-datetime-picker": DatetimePicker,
    "van-dialog": Dialog.Component,
    "van-field": Field,
    // "van-loading": Loading,
    // "van-icon": Icon,
  },
  setup() {
    // 空状态图片
    const emptyImg = ref(require("../../assets/image/custom-empty-image.png"));
    // 是否显示骨架屏
    const skeleton = ref(true);
    const show = ref(false);
    const showLayerPopover = ref(false);
    const showBuildingPopover = ref(false);
    const showCampusPopover = ref(false)
    const currentPage = ref(1);
    const date = ref("");
    const value = ref("");
    const showDate = ref(false);
    const option2 = ref([]);
    const permissions = ref('')//当前用户权限
    const campus_name_user = ref('')//当前用户校区
    const formatDate = (date) =>
      `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    const onConfirm = (value) => {
      showDate.value = false;
      date.value = formatDate(value);
      queryParams.start_time = date.value;
      getOrderList();
    };
    const showPopup = () => {
      show.value = true;
    };
    // 查询参数
    let queryParams = reactive({
      keywords: "",
      order_no: "",
      start_time: "",
      end_time: "",
      status: 2,
      building_no: "",
      water_type: "",
      floor_no: "",
      room_no: "",
      campus_id:"",
      page: 1,
      limit: 20,
    });
    // 订单数据
    const list = ref({});
    const dongShopData = ref([]);//栋数品牌数据
    const dongShopNum = ref(0);
    const cengShopData = ref([]);//层数品牌数据
    // 获取订单列表
    const getOrderList = async () => {
      skeleton.value = true;
      try {
        permissions.value = Cookies.get('permissions')
        if(permissions.value !== "all"){
          const campus_id_user = Cookies.get('campus_id')
          queryParams.campus_id = campus_id_user
          campus_id.value = campus_id_user
          campus_name_user.value = Cookies.get('campus_name')
          selectCampus.value = campus_name_user.value 
          getBuildingList()
        }
        const res = await api.getOrder(queryParams);
        if (res.code == 200) {
          res.data.list.forEach((item)=>{
            item.room_name = setRoomText(item.campus_name,item.building_no,item.floor_no,[{room_no:item.room_no}])
          })
          list.value = res.data;

          skeleton.value = false;
          flag.value = false;
          isRefresh.value = false;
          loadingText.value = "下拉刷新";
          top.value = 0;
          //获取品牌列表
          dongShopData.value = []
          cengShopData.value = []
          res.data.brandList.forEach((item)=>{
            item.text = item.brand_name
            item.value = item.brand_id
            dongShopData.value.push({
              name:item.brand_name,
              value:item.buildingBrandNum
            })
            cengShopData.value.push({
              name:item.brand_name,
              value:item.floorBrandNum
            })
          })
          dongShopNum.value = res.data.brandList.length
          res.data.brandList.unshift({text:'全部品牌',value:0})
          option2.value = res.data.brandList
        }
      } catch (error) {
        Toast("获取订单列表出错");
        skeleton.value = false;
      }
    };

    //获取校区列表
    const campus_id =ref('')
    const campusList = ref([])
    const getCampusListFun = async()=>{
      const res = await httpCampus.getCampusList();
      if(res.code == 200){
        campusList.value = res.data
        selectCampus.value = res.data[0].campus_name;
        campus_name_user.value = res.data[0].campus_name;
        queryParams.campus_id = res.data[0].campus_id
        getOrderList();
        getBuildingList();
      }
    }
    getCampusListFun()

    // 选择 校区
    const selectCampus = ref("请选择");
    const selectCampusFun = (item) => {
      selectCampus.value = item.campus_name;
      campus_name_user.value = item.campus_name;
      campus_id.value = item.campus_id;
      selectBuild.value = '请选择';
      selectFloor.value = "请选择";
      queryParams.campus_id = item.campus_id;
      queryParams.building_no = "";
      queryParams.floor_no = "";
      //品牌初始化
      value2.value = 0;
      queryParams.brand_id = ""
      
      getBuildingList();
      getOrderList();
      showCampusPopover.value = false;
    };

    // 获取 幢 列表
    const buildArr = ref([]);
    const getBuildingList = async () => {
      try {
        const res = await api.getAllBuilding({
          campus_id:campus_id.value
        });
        if (res.code == 200) {
          buildArr.value = setBuildText(selectCampus.value,res.data);
        }
      } catch (error) {
        // console.log(error);
        Toast("获取幢列表出错");
      }
    };
    //打开幢
    const openBuildingList = ()=>{
       if (selectCampus.value == "请选择") {
        Toast("请先选择校区");
        showBuildingPopover.value = false;
        return;
      }
    }
    // 选择 幢
    const selectBuild = ref("请选择");
    const showBuilding = (item) => {
      selectBuild.value = item == "全部" ? "请选择" : item.name;
      selectFloor.value = "请选择";
      queryParams.building_no = item == "全部" ? "" : item.id;
      queryParams.floor_no = "";
      getAllFloorList();
      getOrderList();
      showBuildingPopover.value = false;
    };

    

    // 获取层列表
    const floorArr = ref([]);
    const getAllFloorList = async () => {
      try {
        const res = await api.getAllFloor({
          building_no: queryParams.building_no,
          campus_id:campus_id.value
        });
        if (res.code === 200) {
          floorArr.value = setCengText(selectCampus.value,queryParams.building_no,res.data);
        }
      } catch (error) {
        Toast("获取层列表出错");
      }
    };

    // 打开层列表
    const openFloorList = () => {
      if (selectBuild.value == "请选择") {
        Toast("请先选择幢");
        showLayerPopover.value = false;
        return;
      }
    };
    // 选择层
    const selectFloor = ref("请选择");
    const showLayer = (item) => {
      selectFloor.value = item == "全部" ? "请选择" : item.name;
      queryParams.floor_no = item == "全部" ? "" : item.id;
      getOrderList();
      showLayerPopover.value = false;
    };

    // 确认搜索条件
    const confirm = () => {
      // console.log(queryParams);
      getOrderList();
      show.value = false;
    };

    // 重置查询参数
    const resetQueryParams = () => {
      queryParams.order_no = "";
      queryParams.start_time = "";
      queryParams.end_time = "";
      queryParams.status = 2;
      queryParams.building_no = "";
      queryParams.water_type = "";
      queryParams.floor_no = "";
      queryParams.room_no = "";
      queryParams.campus_id = "";
      queryParams.page = 1;
      queryParams.limit = 20;
      date.value = "";
      (dateValue.value = "日期"), (selectBuild.value = "请选择");
      selectFloor.value = "请选择";
      getOrderList();
    };

    // 搜索框
    const onSearch = (val) => {
      // console.log(val);
      getOrderList();
    };

    // 分页
    const changePage = (page) => {
      queryParams.page = page;
      getOrderList();
    };

    // 整层送达
    const isAllDelivery = () => {
      if (list.value.length == 0) return Toast("该层没有订单需要配送");
      if (queryParams.campus_id == "" ) {
        return Toast("请先选择 校区");
      }
      if (queryParams.building_no == "" || queryParams.floor_no == "") {
        return Toast("请先选择 幢 和 层");
      }
      Dialog.confirm({
        title: "送达提示",
        confirmButtonText: "是",
        cancelButtonText: "否",
        message: `${queryParams.building_no} 幢 ${queryParams.floor_no} 层所有房间的订单是否全部送达`,
      })
        .then(async () => {
          const res = await httpDeliver.postDelivery({
            building_no: queryParams.building_no,
            floor_no: queryParams.floor_no,
            start_time: queryParams.start_time,
            campus_id:queryParams.campus_id,
          });
          if (res) {
            Toast(res.msg);
            getOrderList();
          }
        })
        .catch(() => {
          // console.log("点击了否");
        });
    };

    //单个 是否送达
    const isOneDelivery = (item) => {
      // console.log(item);
      // 送达提示
      Dialog.confirm({
        title: "送达提示",
        confirmButtonText: "是",
        cancelButtonText: "否",
        message: `${item.building_no} 幢 ${item.floor_no} 层 ${
          item.room_no
        } 号房间的 ${
          item.water_type_name
        } 品牌订单是否送达`,
      })
        .then(async () => {
          const res = await httpDeliver.postDelivery({ id: item.id,campus_id:queryParams.campus_id });
          if (res) {
            Toast(res.msg);
            getOrderList();
          }
        })
        .catch(() => {
          // console.log("点击了否");
        });
    };
    const value1 = ref("日期");
    const value2 = ref(0);
    const value3 = ref(2);
    
    const option3 = [
      { text: "全部状态", value: 0 },
      { text: "已送", value: 3 },
      { text: "未送", value: 2 },
      { text: "退款", value: 4 },
    ];
    const changeBrandItem = () => {
      console.log('value2',value2.value)
      if (value2.value == 0) {
        queryParams.brand_id = "";
        getOrderList();
        return;
      }
      queryParams.brand_id = value2.value;
      getOrderList();
    };
    const changeStatusItem = () => {
      if (value3.value == 0) {
        queryParams.status = "";
        getOrderList();
        return;
      }
      queryParams.status = value3.value;
      getOrderList();
    };
    const showBottom = ref(false);
    const currentDate = ref(new Date());
    const dateValue = ref("日期");
    const confirmDate = (val) => {
      const y = val.getFullYear();
      const m = val.getMonth() + 1;
      const d = val.getDate();
      const date = y + "-" + m + "-" + d;
      queryParams.start_time = date;
      dateValue.value = date;
      getOrderList();
      showBottom.value = false;
    };
    // 取消用户订单并退款
    // 退款弹窗
    const refundShow = ref(false);
    // 退款提示
    const refundText = ref("是否取消该订单并退款给该用户");
    // 退款理由
    const refundParams = reactive({
      order_id: "",
      reason: "",
    });
    const cancelOrder = (item) => {
      refundShow.value = true;
      refundParams.order_id = item.id;
      refundText.value = `是否取消 ${item.building_no} 栋 ${item.floor_no}层 ${item.room_no} 房的订单，并退款给该用户`;
    };
    const confirmRefund = async () => {
      try {
        const res = await httpOrder.orderRefund({ ...refundParams });
        if (!res) return;
        if (res.code == 200) {
          Toast(res.msg);
          getOrderList();
        }
      } catch (error) {
        error;
        return Toast("取消订单出错");
      }
    };
    const {
      flag,
      isRefresh,
      loadingText,
      top,
      touchStart,
      touchMove,
      touchEnd,
    } = refresh();

    return {
      // property
      emptyImg,
      show,
      date,
      showDate,
      showLayerPopover,
      showBuildingPopover,
      showCampusPopover,
      currentPage,
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2100, 10, 1),
      currentDate,
      list,
      skeleton,
      buildArr,
      campusList,
      selectBuild,
      floorArr,
      selectFloor,
      queryParams,
      value,
      value1,
      value2,
      value3,
      // option1,
      option2,
      option3,
      showBottom,
      dateValue,
      refundShow,
      refundText,
      refundParams,
      flag,
      loadingText,
      isRefresh,
      top,
      selectCampus,
      permissions,
      campus_name_user,
      dongShopData,
      cengShopData,
      dongShopNum,

      // method
      showPopup,
      onConfirm,
      changePage,
      getBuildingList,
      showBuilding,
      showLayer,
      openFloorList,
      openBuildingList,
      confirm,
      resetQueryParams,
      onSearch,
      isOneDelivery,
      isAllDelivery,
      changeBrandItem,
      changeStatusItem,
      confirmDate,
      cancelOrder,
      confirmRefund,
      selectCampusFun,

      // 下拉刷新
      touchStart,
      touchMove,
      touchEnd,
    };
  },
};
</script>
<style scoped lang="less">
.dist-order-box {
  .dist-order-header {
    display: flex;
    align-items: center;
    justify-content: center;
    .left {
      flex: 1;
    }
    .right {
      width: 216px;
    }
  }
  .building-layer-box {
    border-bottom: 2px solid #ccc;
    margin-bottom: 40px;
    .item {
      border-top: 2px solid #ccc;
      padding: 20px;
      font-size: 32px;
    }
  }
}
.table-box {
  //   margin: 0 20px;
  box-sizing: border-box;
}
.fl-table {
  border-radius: 10px;
  font-size: 24px;
  font-weight: normal;
  border: none;
  border-collapse: collapse;
  white-space: nowrap;
  background-color: white;
  thead {
    th {
      border-color: #ccc;
      font-weight: normal;
    }
  }
  td {
    border-right: 1px solid #ccc;
    font-size: 12px;
  }
  td,
  th {
    text-align: center;
    padding: 8px;
    font-size: 30px;
    box-sizing: border-box;
  }
}
tr {
  height: 80px;
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}
th {
  display: table-cell;
  vertical-align: inherit;
  font-weight: bold;
  text-align: -internal-center;
}
.date {
  font-size: 24px;
  margin-top: 20px;
  border-bottom: 2px solid #ccc;
  padding-bottom: 20px;
}
.status,
.brand {
  font-size: 26px;
  margin-top: 20px;
  border-bottom: 2px solid #ccc;
  padding-bottom: 40px;
  .span-text {
    padding: 10px;
    // border: 2px solid #ccc;
    border-radius: 10px;
  }
  .span-text + .span-text {
    margin-left: 20px;
  }
}
.fl-table > tbody > tr,
.fl-table > thead {
  display: table;
  width: 100%;
  table-layout: fixed; /* 重要  表格固定算法 */
}
.fl-table > tbody {
  height: 700px;
  overflow: hidden;
  display: block;
  overflow: hidden;
  overflow-y: auto;
  overflow-x: auto;
}
.confirm-btn {
  position: absolute;
  left: 0;
  bottom: 60px;
  width: 100%;
  padding: 60px;
  box-sizing: border-box;
}
.select-data {
  background-color: white;
  min-width: 100px;
  height: 100px;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  margin-left: 10px;
  max-width: 200px;
}
.all-status {
  margin-right: 10px;
}
.custom-image .van-empty__image {
  width: 90px;
  height: 90px;
}
.van-empty {
  margin: 150px 0;
}
.red {
  color: red;
}
:v-deep(.van-popover--light) {
  left: 20px !important;
}
::v-deep .pagination .van-pagination__item {
  height: 64px !important;
}
::v-deep .van-grid-item__content {
  width: 140px;
  height: 52px;
  border: 2px solid #ccc;
  padding: 0;
  margin-left: 10px;
}
::v-deep .date .van-cell__title {
  text-align: left;
}
.van-grid-item {
  padding-top: 100px !important;
}
.van-grid {
  max-height: 480px !important;
  overflow-y: auto !important;
}
::v-deep .van-cell,
::v-deep .van-grid-item__text,
::v-deep .van-button {
  font-size: 32px;
}
::v-deep .van-dropdown-menu__bar {
  box-shadow: none;
}
::v-deep .van-dropdown-menu__item + .van-dropdown-menu__item .van-ellipsis {
  background-color: red;
  color: white;
  font-weight: bold;
  padding: 4px 20px;
  border-radius: 30px;
}
.right-box{
  width: calc(100% - 200px);
}
.scroll-right{
  width: calc(100% - 200px);
  height: 160px;
  overflow: hidden;
  overflow-x: scroll;
}
.scroll-right-item{
  width: 140px;
  height: 100%;
  float: left;
  display: block;
  margin-right: 60px;
}
.scroll-right-item p {
  margin:0;
  margin-bottom: 20px;
}
.scroll-right-item p:nth-of-type(2) {
  margin:0;
}
</style>
